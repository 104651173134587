<template>
	<div style="margin: 10px;">
		
		<a-row style="margin: 10px 0;">
		  <a-col :span="20"><a-alert :message="tips" type="success" /></a-col>
		  <a-col :span="4">
			<a-button icon="download" @click="save()" :disabled="songsList.length<=0" type="primary">保存歌单</a-button>
		  </a-col>
		</a-row>
		
		<a-table ref="danmulist" :columns="columns" :data-source="songsList" bordered :pagination="false" :locale="{emptyText: '暂无人点歌'}">
			<template slot="time" slot-scope="text, record">
				<a-button class="text" @click="copy(text)" type="link">{{text}}</a-button>
			</template>
			<template slot="uname" slot-scope="text, record">
				<a-button class="text" @click="copy(text)" type="link">{{text}}</a-button>
			</template>
			<template slot="text" slot-scope="text, record">
				<a-button class="text" @click="copy(text)" type="link">{{text}}</a-button>
			</template>
			<template slot="song" slot-scope="text, record">
				<a-button class="text" @click="copy(text)" type="link">{{text}}</a-button>
			</template>
			<template slot="singer" slot-scope="text, record">
				<a-button class="text" @click="copy(text)" type="link">{{text}}</a-button>
			</template>
			<template slot="action" slot-scope="text, record">
				<a-row>
					<a-col :span="12"><a-button @click="copy(record.text)" type="primary">复制</a-button></a-col>
					<a-col :span="12"><a-button @click="remove(record)" type="danger">删除</a-button></a-col>
				</a-row>
			</template>
		</a-table>
	</div>
</template>

<script>
	const decompress = require('brotli/decompress')
	const columns = [{
			title: '序号',
			width: 50,
			dataIndex: 'no'
		},{
			title: '时间',
			width: 130,
			dataIndex: 'time',
			scopedSlots: {
				customRender: 'time'
			}
		},
		{
			title: '用户',
			width: 100,
			className: 'uname',
			dataIndex: 'uname',
			scopedSlots: {
				customRender: 'uname'
			}
		},
		{
			title: '原弹幕',
			width: 200,
			dataIndex: 'text',
			scopedSlots: {
				customRender: 'text'
			}
		},
		{
			title: '歌名',
			width: 100,
			dataIndex: 'song',
			scopedSlots: {
				customRender: 'song'
			}
		},
		{
			title: '歌手',
			width: 100,
			dataIndex: 'singer',
			scopedSlots: {
				customRender: 'singer'
			}
		},
		{
			title: '操作',
			width: 120,
			scopedSlots: {
				customRender: 'action'
			}
		}
	];
	export default {
		data() {
			return {
				tips: '仅展示包含"点歌"的弹幕，点击文字可复制对应内容',
				songsList: [],
				columns,
			};
		},
		watch: {},
		created() {
			this.initWebSocket()
		},
		methods: {
			remove(e) {
				const index = e.$index
				let songsList = this.songsList
				if (songsList.length > 1) {
					this.songsList = songsList.splice(index, 1)
				} else {
					this.songsList = []
				}
				this.$message.success('已删除')
			},
			copy(text) {
				if (text.length <= 0) {
					return
				}
				text = text.replace('点歌', '')
				this.$copyText(text).then((e) => {
					this.$message.success('已复制到粘贴板')
				}, (e) => {
					this.$message.success('未能复制')
				})
			},
			initWebSocket() {
				// 初始化weosocket
				const wsuri = 'wss://zj-cn-live-comet.chat.bilibili.com:2245/sub' // ws地址
				this.websocket = new WebSocket(wsuri)
				this.websocket.onopen = this.websocketOnopen
				this.websocket.onerror = this.websocketOnerror
				this.websocket.onmessage = this.websocketOnmessage
				this.websocket.onclose = this.websocketClose
			},
			websocketOnopen() {
				var certification = {
					'uid': 0,
					'roomid': 22387371,
					'buvid':'FED0C2A3-98D8-43BE-BCD4-EAE8ED81A0D149123infoc',
					'protover': 3,
					'platform': 'web',
					'type': 2,
					'key': 'xRDa7erGoSQmYy7L8StrcZ2DuhB5C6ttMDhzsbHGCs_YVasn4Tvifok6tF4Wgp6FvattubEuwJgVc-lWk9FGbyuhNj8n6MpfBkdtmvDkP00Z2Jxah8JXWud2Sxxz4MKBxfYfPcXwI2wYJgYCGr3PFDEUoDiiGLsZEeEfiySWq8MDf5D-cL0='
				}
				const sendData = getCertification(JSON.stringify(certification))
				this.websocket.send(sendData)
				console.log(JSON.stringify(certification))
				// 发送心跳包
				const _this = this
				const timer = setInterval(function() {
					let buff = new ArrayBuffer(16)
					let i = new DataView(buff)
					i.setUint32(0, 0) // 整个封包
					i.setUint16(4, 16) // 头部
					i.setUint16(6, 1) // 协议版本
					i.setUint32(8, 2) // 操作码,2为心跳包
					i.setUint32(12, 1) // 填1
					_this.websocket.send(buff)
				}, 30000) // 30秒
				// 生成认证数据
				function getCertification(json) {
					let encoder = new TextEncoder() // 编码器
					let jsonView = encoder.encode(json) // utf-8编码
					let buff = new ArrayBuffer(jsonView.byteLength + 16) // 数据包总长度：16位头部长度+bytes长度
					let view = new DataView(buff) // 新建操作视窗
					view.setUint32(0, jsonView.byteLength + 16) // 整个数据包长度
					view.setUint16(4, 16) // 头部长度
					view.setUint16(6, 1) // 协议版本
					view.setUint32(8, 7) // 类型,7为加入房间认证
					view.setUint32(12, 1) // 填1
					for (let r = 0; r < jsonView.byteLength; r++) {
						view.setUint8(16 + r, jsonView[r]) // 填入数据
					}
					return buff
				}
			},
			websocketOnerror(e) {
				console.log('WebSocket连接发生错误', e)
			},
			websocketOnmessage(e) {
				const data = e.data
				handleMessage(data, function(result, _this) {
					// 触发事件
					for (let i = 0; i < result.length; i++) {
						let json = JSON.parse(result[i])
						let danmu = {}
						if (json.Type !== 5) continue
						if (json.Type === 5) {
							const body = JSON.parse(json.body)
							danmu = cmdEventHandle(body.cmd, body)
						}
						if (!danmu || Object.keys(danmu).length === 0) continue
						// 非点歌弹幕
						if (danmu.text.indexOf('点歌') === -1) continue
						// 跳过黑名单用户的点歌
						const blackUsers = ['伊利专属智能助手']
						if (blackUsers.includes(danmu.uname)) continue
						// 处理歌曲信息
						let song = ''
						let singer = ''
						let text = danmu.text.replace('点歌', '').replace('排队', '').replace('《', '').replace('》', '')
						if (text.indexOf('-') >= 0) {
							const songInfo = text.split('-')
							if (songInfo.length === 2) {
								song = songInfo[0]
								singer = songInfo[1]
							}
						}
						if ((!song && !singer) && text.indexOf(' ') >= 0) {
							const songInfo = text.split(' ')
							if (songInfo.length === 2) {
								song = songInfo[0]
								singer = songInfo[1]
							}
							if (songInfo.length === 3) {
								song = songInfo[1]
								singer = songInfo[2]
							}
						}
						let songsList = _this.songsList
						songsList = songsList.concat(Object.assign(danmu, {
							song: song,
							singer: singer,
							no: songsList.length + 1
						}))
						if (songsList.length > 1000) songsList = songsList.slice(-1000)
						_this.songsList = songsList.reverse()
					}
				}, this)
				// 事件类型处理
				function cmdEventHandle(cmd, e) {
					let msgText = ''
					// 认证成功
					if (cmd === 'Certify_Success') {
						if (e.code === 0) {
							console.log('Certify_Success')
						}
					}
					// 弹幕事件
					if (cmd.indexOf('DANMU_MSG') >= 0) {
						console.log('弹幕信息->', e)
						let uname = e.info[2][1]
						let timedata = new Date(e.info[9].ts * 1000)
						let time = timedata.toLocaleDateString() + ' ' + timedata.toTimeString().split(' ')[0]
						// let time = timedata.toTimeString().split(' ')[0]
						let text = e.info[1]
						msgText = time + ' ' + uname + '：' + text
						console.log(msgText)
						return {
							time: time,
							uname: uname,
							text: text
						}
					}
					return {}
				}
				// 消息处理
				function handleMessage(blob, call, _this) {
					let reader = new FileReader()
					reader.onload = function(e) {
						let buff = e.target.result // ArrayBuffer对象
						let decoder = new TextDecoder() // 解码器
						let view = new DataView(buff) // 视图
						let offset = 0
						let packet = {}
						let result = []
						while (offset < buff.byteLength) { // 数据提取
							let packetLen = view.getUint32(offset)
							let headLen = view.getUint16(offset + 4)
							let packetVer = view.getUint16(offset + 6)
							let packetType = view.getUint32(offset + 8)
							let num = view.getUint32(12)
							// console.log('packetVer', packetVer)
							if (packetVer === 3) { // 解压数据
								let brArray = new Uint8Array(buff, offset + headLen, packetLen - headLen)
								let buffFromBr = decompress(brArray) // 返回Int8Array视图
								let view = new DataView(buffFromBr.buffer)
								let offsetVer3 = 0
								while (offsetVer3 < buffFromBr.byteLength) { // 解压后数据提取
									let packetLen = view.getUint32(offsetVer3)
									let headLen = view.getUint16(offsetVer3 + 4)
									let packetVer = view.getUint16(offsetVer3 + 6)
									let packetType = view.getUint32(offsetVer3 + 8)
									let num = view.getUint32(12)
									packet.Len = packetLen
									packet.HeadLen = headLen
									packet.Ver = packetVer
									packet.Type = packetType
									packet.Num = num
									let dataArray = new Uint8Array(buffFromBr.buffer, offsetVer3 + headLen, packetLen -
										headLen)
									packet.body = decoder.decode(dataArray) // utf-8格式数据解码，获得字符串
									result.push(JSON.stringify(packet)) // 数据打包后传入数组
									offsetVer3 += packetLen
								}
							} else {
								packet.Len = packetLen
								packet.HeadLen = headLen
								packet.Ver = packetVer
								packet.Type = packetType
								packet.Num = num
								let dataArray = new Uint8Array(buff, offset + headLen, packetLen - headLen)
								if (packetType === 3) { // 获取人气值
									packet.body = (new DataView(buff, offset + headLen, packetLen - headLen))
										.getUint32(0) // 若入参为dataArray.buffer，会返回整段buff的视图，而不是截取后的视图
								} else {
									packet.body = decoder.decode(dataArray) // utf-8格式数据解码，获得字符串
								}
								result.push(JSON.stringify(packet)) // 数据打包后传入数组
							}
							offset += packetLen
						}
						call(result, _this) // 数据后续处理
					}
					reader.readAsArrayBuffer(blob) // 读取服务器传来的数据转换为ArrayBuffer
				}
			},
			websocketsend(agentData) {
				// 数据发送
				this.websocket.send(agentData)
			},
			websocketClose() {
				// 关闭
				console.log('close')
				if (timer != null) {
					clearInterval(timer) // 停止发送心跳包
				}
			},
			save() {
				const data = [
					["歌名", "歌手", "点歌用户", "点歌时间"]
				]
				this.songsList.forEach(element => {
					data.push([element.song, element.singer, element.uname, element.time])
				})
				const filename = data[1][3] + "点歌记录"
				this.exportCsv(data, filename)
			},
			exportCsv: function(list, name) {
				const newList = list.map(res => res.join(','))
				const data = newList.join(',\n')
				var uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(data);
				var downloadLink = document.createElement("a");
				downloadLink.href = uri;
				downloadLink.download = (name + ".csv") || "点歌记录.csv";
				document.body.appendChild(downloadLink);
				downloadLink.click();
				document.body.removeChild(downloadLink);
			}
		}
	};
</script>

<style>
	.text {
		color: #000;
	}
</style>