import Vue from 'vue'
import Antd from 'ant-design-vue'
import App from './App'
import 'ant-design-vue/dist/antd.css'
import VueClipboard from 'vue-clipboard2'
Vue.config.productionTip = false

Vue.use(Antd).use(VueClipboard)

new Vue({
  render: h => h(App),
}).$mount('#app')